/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; margin: 0; padding: 0; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
  font-family: 'fjalla';
  src : url("app/img/FjallaOne-Regular.ttf");
}
.fjalla{
  font-family: fjalla;
}
::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  background-color: #000000;
}
